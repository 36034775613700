<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="email"
                        :name="beForm[rep].email.label"
                        :label="beForm[rep].email.label"
                        v-model="form[rep].email"
                        placeholder="Inserisci una email"
                        :rules="{ email: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="username"
                        :name="beForm[rep].username.label"
                        :label="beForm[rep].username.label"
                        v-model="form[rep].username"
                        placeholder="Inserisci uno username"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="description"
                        :name="beForm[rep].description.label"
                        :label="beForm[rep].description.label"
                        v-model="form[rep].description"
                        placeholder="Inserisci una descrizione"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <b-button-toolbar>
                        <b-button-group>
                          <base-input
                            name="password"
                            vid="password"
                            label="Password"
                            v-model="form[rep].password"
                            :type="showPassword ? 'text' : 'password'"
                            autocomplete="off"
                          />
                          <b-button
                            class="mt-4 btn-quick"
                            size="sm"
                            text="Button"
                            variant="lisaweb"
                            :title="buttonLabel"
                            @click="toggleShow"
                            :rules="getRules('password', 'user')"
                            ><b-icon icon="eye" v-if="!showPassword"></b-icon>
                            <b-icon v-else icon="eye-slash-fill"></b-icon
                          ></b-button>
                        </b-button-group>
                      </b-button-toolbar>
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_limited"
                        :name="beForm[rep].is_limited.label"
                        :label="getDictionary('is_limited', rep)"
                        v-model="form[rep].is_limited"
                        :options="beForm[rep].is_limited.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('is_limited')"
                      />
                      <!-- :disabled="!isAdmin()" -->
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">{{ msg || "Operazione in corso..." }}</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
// import { isAdmin } from "@/utils/permissions";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "user",
      id: this.$route.params.id,
      showPassword: false,
      form: {
        user: {
          username: null,
          email: null,
          password: null,
          role_id: null,
          is_limited: null,
          description: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
  },
  methods: {
    // isAdmin,
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    onSubmit() {
      this.loadMsg();
      this.isLoading = true;
      this.store(this.repository)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Utente creato`,
          });
          this.shortcut("users.index", null, null, "filterUsers");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.unloadMsg();
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.form[this.rep].role_id = this.beForm[this.rep].role_id.options.find(
        (role) => role.text === "operatore"
      ).value;
      this.isLoading = false;
    });
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Nascondi" : "Mostra";
    },
    rep() {
      return this.repository;
    },
  },
};
</script>
