<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-card header="Modifica configurazioni App" header-tag="header">
          <b-card-text>
            <!-- v-for="(report, index) in lisa_for_apps_reports" -->
            <b-card
              :header="`${report.formatted_title}`"
              header-tag="header"
              class="mt-2"
              v-for="(report, index) in lisa_for_apps_reports"
              :key="index"
            >
              <b-row>
                <div class="form-group col-md-3">
                  <base-select
                    name="estrai"
                    label="Estrai"
                    v-model="form[rep][report.code].can_extract"
                    :options="[
                      { value: 'Y', text: 'Sì' },
                      { value: 'N', text: 'No' },
                    ]"
                  />
                </div>
                <div class="form-group col-md-3">
                  <base-select
                    name="mostra"
                    label="Mostra Premio"
                    v-model="form[rep][report.code].show_premium"
                    :options="[
                      { value: 'Y', text: 'Sì' },
                      { value: 'N', text: 'No' },
                    ]"
                  />
                </div>
              </b-row>
            </b-card>
          </b-card-text>
        </b-card>
        <div class="py-2 mb-4">
          <b-button
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onSavePermissions"
            :disabled="!canVerb(resource, 'update')"
          >
            Salva
          </b-button>
          <b-button
            @click="$router.back()"
            variant="outline-lisaweb"
            size="sm"
            class="btn-reset float-right"
            ><b-icon-chevron-double-left
              font-scale="0.9"
            ></b-icon-chevron-double-left
            >Torna indietro</b-button
          >
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      isLoading: false,
      repository: "lisa_for_app",
      resource: "lisa_for_apps",
      user: this.$route.params.user,
      lisa_for_apps_reports: [], // this.$store.state.auth.lisa_for_apps,
      treasury: null,
      form: {
        lisa_for_app: {},
      },
    };
  },
  components: {
    BaseIcon,
    BaseSelect,
  },
  methods: {
    ...mapGetters("auth", {
      getLisaForAppsPermissions: "lisa_for_apps",
    }),
    onSavePermissions() {
      let payload = {
        [this.repository]: {},
      };

      //  POST /api/users/:user/pivot/:lisa_for_app
      // payload = {
      //   lisa_for_app: {
      //     1: {
      //       can_extract: "Y",
      //       show_premium: "Y",
      //     },
      //   },
      // };
      // for (const report in this.form[this.repository]) {
      for (const report of this.lisa_for_apps_reports) {
        payload[this.repository][report.id] = {
          can_extract: this.form[this.repository][report.code].can_extract,
          show_premium: this.form[this.repository][report.code].show_premium,
        };
      }
      this.isLoading = true;
      this.addLisaForAppPermission(this.user.id, this.repository, payload)
        .then(() => {
          this.$store.dispatch("auth/lisa_for_apps");
          this.$showSnackbar({
            preset: "info",
            text: "Permessi Aggiornati",
          });
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    addLisaForAppPermission(id, relation, payload) {
      const Repo = RepositoryFactory.get("user");
      return Repo.pivot_store(id, relation, payload);
    },
  },
  beforeMount() {
    this.isLoading = true;
    // load permissions for this user
    const Repo = RepositoryFactory.get(this.repository);
    let queryString = `bySpecifiedUser=${this.user.id}`;
    Repo.index(queryString)
      .then((response) => {
        let data = response.data.data;
        // prepare the form, since api/edit is not available for this resource...
        // hence there is not any beForm or any other related stuff
        data.forEach((e) => {
          if (!this.form[this.repository][e.code]) {
            this.$set(this.form[this.repository], e.code, {});
          }
          this.$set(
            this.form[this.repository][e.code],
            "can_extract",
            e.users.length ? e.users[0].pivot.can_extract.value : "N"
          );
          this.$set(
            this.form[this.repository][e.code],
            "show_premium",
            e.users.length ? e.users[0].pivot.show_premium.value : "N"
          );
          // fill the array to be looped in order to draw the form inputs by v-for above in markup
          this.lisa_for_apps_reports.push({
            id: e.id,
            code: e.code,
            formatted_title: e.formatted_title,
          });
        });
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: errMsg,
        });
        this.isLoading = false;
      });
  },
  created() {
    console.log(this.lisa_for_apps_reports);
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
