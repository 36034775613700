<template>
  <div class="mt-1 mx-3">
    <create-user> </create-user>
  </div>
</template>

<script>
import CreateUser from "@/components/profile/permissions/users/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CreateUser,
  },
  created() {
    this.notifyQueue({
      text: "Crea Utente",
      path: "users.create",
      level: 3,
    });
  },
};
</script>
