<template>
  <div class="mt-1 mx-3">
    <brokers-permissions> </brokers-permissions>
  </div>
</template>

<script>
import BrokersPermissions from "@/components/profile/permissions/brokers/index.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
    ...mapActions("breadcrumbs", { removeBCS: "remove" }),
    ...mapGetters("breadcrumbs", { loadBCS: "loadBreadcrumbs" }),
  },
  components: {
    BrokersPermissions,
  },
  beforeMount() {
    let bcs = this.loadBCS();
    if (this.$route.query.fromShortcut) {
      bcs.push({
        text: `Modifica Permessi Produttore :: ${this.$route.params.user.username}`,
        path: "brokers.edit3",
        level: 3,
      });
      bcs.forEach((element) => {
        this.notifyQueue({
          text: element.text,
          path: element.path,
          tab: element.tab,
          level: element.level,
          disabled: element.disabled,
        });
      });
    } else {
      this.notifyQueue({
        text: `Modifica Permessi Produttore :: ${this.$route.params.user.username}`,
        path: "brokers.edit3",
        level: 3,
      });
    }
  },
};
</script>
