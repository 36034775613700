<template>
  <div class="mt-1 mx-3">
    <users> </users>
  </div>
</template>

<script>
import Users from "@/components/profile/permissions/users/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Users,
  },
  created() {
    this.notifyQueue({
      text: "Profilo",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Profilazione Utenti",
      path: "users.index",
      level: 2,
    });
  },
};
</script>
